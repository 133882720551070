import { AxiosInstance } from "axios";
import { map, path, pathOr } from "ramda";

import { SPARTACUS_AUTH } from "../context/useBomUtils";

type ReducedBomProduct = {
  articleNumber: string;
  amount: number;
  isCable: boolean;
};

const CART_ENDPOINTS = {
  "www-uat.lapp.com": "https://api-uat.lapp.com/occ/v2",
  "www-tst.lapp.com": "https://api-tst.lapp.com/occ/v2",
  "www.lapp.com": "https://api-shop.lapp.com/occ/v2",
};
export const CART_ENDPOINT = pathOr(
  CART_ENDPOINTS["www.lapp.com"],
  [window.location.host],
  CART_ENDPOINTS,
);

const CART_ACCESS_TOKEN_LS = function (): string | undefined {
  return path<string>(
    ["token", "access_token"],
    JSON.parse(localStorage.getItem(SPARTACUS_AUTH) || "{}"),
  );
};

export async function requestCartId(
  axiosSimple: AxiosInstance,
  isLoggedIn: boolean,
  language: Locale,
  currency: string,
): Promise<string> {
  const baseURL = `${CART_ENDPOINT}/${language.lang2}/users`;

  if (isLoggedIn) {
    const result = await axiosSimple.post(
      `${baseURL}/current/carts?fields=DEFAULT&lang=${language.lang1}&curr=${currency}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${CART_ACCESS_TOKEN_LS()}`,
        },
      },
    );
    return pathOr("", ["data", "code"], result);
  }

  const unauthorizedResult = await axiosSimple.post(
    `${baseURL}/anonymous/carts?fields=DEFAULT&lang=${language.lang1}&curr=${currency}`,
  );
  return pathOr("", ["data", "guid"], unauthorizedResult);
}

export async function sendBomProductsToCart(
  axiosSimple: AxiosInstance,
  bom: ReducedBomProduct[],
  cartId: string,
  isLoggedIn: boolean,
  language: Locale,
  currency: string,
  article: string,
) {
  const sourceType = "CONFIGURATOR";
  const classification = article;
  const items = map(
    ({ articleNumber, amount, isCable }) => ({
      quantity: isCable ? 1 : amount,
      selectedStandardLength: isCable ? amount : 1,
      material: articleNumber,
    }),
    bom,
  );

  const baseURL = `${CART_ENDPOINT}/${language.lang2}/users/${
    isLoggedIn ? "current" : "anonymous"
  }/carts`;

  return axiosSimple.post(
    `${baseURL}/${cartId}/inno/basket/quickorder/import/json?lang=${language.lang1}&curr=${currency}`,
    { sourceType, classification, items },
    {
      headers: {
        "Accept-Language": language.lang1,
        ...(isLoggedIn && {
          Authorization: `Bearer ${CART_ACCESS_TOKEN_LS()}`,
        }),
      },
    },
  );
}
