import { equals, forEach } from "ramda";
import { ReactNode, createContext, useEffect, useState } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { useSettings } from "../../../context/useSettings";
import {
  deleteParameter,
  getSelectorDatasheet,
} from "../../../service/configurationService";
import { deepParameterToUndoSearch } from "./servoUtils";

export interface UseServoStore {
  dataSheet: ContainerTO | undefined;
  isLoading: boolean;

  undo(parameterId: string, containerId: string): Promise<void>;
}

export const ServoContext = createContext<UseServoStore>({
  dataSheet: undefined,
  isLoading: false,

  undo: async function () {
    throw new Error("not initialized");
  },
});
export const ServoProvider = ServoContext.Provider;

export function useServo(): UseServoStore {
  const { axios } = useSettings();
  const [dataSheet, setDatasheet] = useState<ContainerTO | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { guiTO, cfg } = useConfigurationContext();

  useEffect(() => {
    if (guiTO && cfg) {
      const isTerminal = equals(guiTO.rootContainer.readyState, "READY");
      if (isTerminal) {
        setIsLoading(true);
        getSelectorDatasheet(axios, cfg.id())
          .then((data) => {
            setDatasheet(data);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log("Error while fetching datasheet:", e);
            setIsLoading(false);
          });
      } else if (dataSheet) {
        setDatasheet(undefined);
      }
    }
  }, [guiTO]);

  async function undo(parameterId: string, containerId: string) {
    if (guiTO && cfg) {
      const parameterToChange = deepParameterToUndoSearch(
        parameterId,
        containerId,
        [guiTO.rootContainer],
      );
      if (parameterToChange) {
        const configurationId = cfg.id();
        await Promise.all(
          forEach(
            (parameter) =>
              deleteParameter(axios, configurationId, parameter.id),
            parameterToChange[1],
          ),
        );
      }
    }
  }

  return { dataSheet, isLoading, undo };
}

export function ServoStore({ children }: Readonly<{ children: ReactNode }>) {
  return <ServoProvider value={useServo()}>{children}</ServoProvider>;
}
