import { BomState, useBomStore } from "bom";
import { equals, gt, isEmpty, not, path, pathOr, slice, toLower } from "ramda";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getBom, getTotal } from "../service/bomService";
import { sendBomProductsToCart } from "../service/cartService";
import { AppContext } from "./useApp";
import {
  SPARTACUS_CURRENCY,
  getButtonOrder,
  getCartId,
  isLoggedIn,
  prepareBom,
  toVisibility,
} from "./useBomUtils";
import { useConfigurationContext } from "./useConfiguration";
import { ProductContext } from "./useProducts";
import { useSettings } from "./useSettings";

const initialState: BomState = {
  bom: [],
  text: "",
  visibility: {
    price: undefined,
    amount: undefined,
    request: undefined,
    eplan: undefined,
    cart: undefined,
    commentary: undefined,
    share: undefined,
    copy: undefined,
    reset: undefined,
  },
  buttonOrder: [],
  total: {
    amount: 1,
    currencyCode: "EUR",
    price: 0,
  },
  endpoint: {
    shop: undefined,
    cart: undefined,
  },
};

const initialStore: useBomStore = {
  ...initialState,
  setText() {
    throw new Error("setText not defined. Context BomStore not initialized");
  },
  setAmount() {
    throw new Error("setAmount not defined. Context BomStore not initialized");
  },
  sendCart() {
    throw new Error("sendCart not defined. Context BomStore not initialized");
  },
};

export const BomContext = createContext<useBomStore>(initialStore);
export const BomProvider = BomContext.Provider;

const CART_INFO_KEY = (lang: string) => `spartacus⚿${lang}⚿cart`;

export function useBom(): useBomStore {
  const { settings, axios, axiosSimple } = useSettings();
  const { locale } = useParams();
  const { i18n } = useTranslation();
  const { guiTO, cfg, article } = useConfigurationContext();
  const { getProducts } = useContext(ProductContext);
  const { country } = useContext(AppContext);
  const [bomStore, setBomStore] = useState<BomState>(initialStore);

  async function fetchData(configurationId: string) {
    // unable to Promise.all, not thread safe
    const total = await getTotal(axios, configurationId, i18n.language);
    const bom = await getBom(axios, configurationId, i18n.language);
    return { bom, total };
  }

  async function determineBom() {
    if (cfg && article) {
      const { total, bom } = await fetchData(cfg.id());
      const product = (await getProducts(article))[article];
      const countryProduct = (await getProducts(article, country))[article];
      const visibility = toVisibility(countryProduct);
      const buttonOrder = getButtonOrder(countryProduct);
      setBomStore({
        bom,
        text: bomStore.text,
        total: {
          amount: bomStore.total.amount,
          ...total,
        },
        endpoint: {
          shop: path<string | undefined>(
            [
              "characteristicValues",
              settings.studio.boms.cartEndpoint,
              "possibleValues",
              "url",
              "value",
            ],
            product,
          ),
          cart: path<string | undefined>(
            [
              "characteristicValues",
              settings.studio.boms.shopEndpoint,
              "possibleValues",
              "url",
              "value",
            ],
            product,
          ),
        },
        visibility: {
          price:
            gt(total.price, -1) &&
            equals(visibility(settings.studio.boms.visible.price), "visible"),
          amount: visibility(settings.studio.boms.visible.amount),
          request: visibility(settings.studio.boms.visible.request),
          eplan: visibility(settings.studio.boms.visible.eplan),
          cart: visibility(settings.studio.boms.visible.cart),
          commentary: visibility(settings.studio.boms.visible.commentary),
          share: visibility(settings.studio.boms.visible.share),
          copy: visibility(settings.studio.boms.visible.copy),
          reset: visibility(settings.studio.boms.visible.reset),
        },
        buttonOrder,
      });
    }
  }

  useEffect(() => {
    determineBom().then();
  }, [guiTO, country]);

  useEffect(() => {
    if (article) {
      setBomStore(initialStore);
    }
  }, [article]);

  function setAmount(amount: number | string): void {
    const nAmount = Number(amount);
    if (equals(nAmount, NaN)) {
      return;
    }
    setBomStore((prev) => ({
      ...prev,
      total: { ...prev.total, amount: nAmount },
    }));
  }

  function setText(text: string): void {
    setBomStore((prev) => ({ ...prev, text }));
  }

  async function sendCart(): Promise<void> {
    if (bomStore.endpoint.shop && bomStore.endpoint.cart) {
      const loginState = isLoggedIn();

      const language: Locale = {
        lang1: toLower(slice(0, 2, locale || i18n.language)),
        lang2: toLower(slice(3, 5, locale || i18n.language)),
      };
      const currency = JSON.parse(
        localStorage.getItem(SPARTACUS_CURRENCY) || '"EUR"',
      );
      const storedCartId = pathOr(
        "",
        ["active"],
        JSON.parse(localStorage.getItem(CART_INFO_KEY(language.lang2)) || "{}"),
      );
      const cartId =
        not(isLoggedIn) || isEmpty(storedCartId)
          ? await getCartId(axiosSimple, loginState, language, currency)
          : storedCartId;

      const reducedBom = prepareBom(bomStore.bom);

      if (not(isEmpty(cartId))) {
        const cartInfo = {
          active: cartId,
        };
        localStorage.setItem(
          CART_INFO_KEY(language.lang2),
          JSON.stringify(cartInfo),
        );
        await sendBomProductsToCart(
          axiosSimple,
          reducedBom,
          cartId,
          loginState,
          language,
          currency,
          article!,
        );
      }
      return;
    }
    throw new Error(
      `Endpoint ${settings.studio.boms.cartEndpoint} not defined`,
    );
  }

  return useMemo(
    () => ({ ...bomStore, setAmount, sendCart, setText }),
    [bomStore],
  );
}

export function BomStore({ children }: Readonly<{ children: ReactNode }>) {
  return <BomProvider value={useBom()}>{children}</BomProvider>;
}
