import { ConfiguratorMetaData, StepMetaData } from "emosTypes";
import { equals, isNil, pathOr } from "ramda";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { ConfigurationService } from "@encoway/c-services-js-client";

import { ARTICLE_NAME_PATH, CONFIGURATION_ID_PATH } from "../utils/econdaUtils";
import { useSettings } from "./useSettings";

type AnalyticsStore = {
  send: (
    analyticsContent: string,
    language: string,
    cfg?: ConfigurationService,
    stepMetaData?: StepMetaData,
  ) => void;
};

const AnalyticsContext = createContext<AnalyticsStore | null>(null);

export function AnalyticsContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const { settings } = useSettings();
  const counterRef = useRef(-1);

  const siteid = equals(settings.server.reactAppEnvironment, "test")
    ? `test-${settings.analytics.siteid}`
    : settings.analytics.siteid;

  const pageId = settings.analytics.pageId;

  useEffect(() => {
    window.emos3 = window.emos3 || {};
    if (typeof window.emos3.send === "function") {
      counterRef.current = 0;
      window.emos3.send({
        siteid,
        pageId,
        content: "document ready",
      });
    }
  }, [siteid, pageId]);

  function send(
    analyticsContent: string,
    language: string,
    cfg?: ConfigurationService,
    stepMetaData: StepMetaData = ["", "", ""],
  ) {
    counterRef.current += 1;
    const configurator: ConfiguratorMetaData = [
      counterRef.current,
      ...stepMetaData,
      pathOr("", CONFIGURATION_ID_PATH, cfg),
      analyticsContent,
      `${pathOr("", ARTICLE_NAME_PATH, cfg)}/${language}`,
    ];
    window.emos3.send({ siteid, pageId, configurator });
  }

  const value = useMemo(() => ({ send }), []);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (isNil(context)) {
    throw new Error(
      "useAnalyticsContext must be used within AnalyticsContextProvider",
    );
  }
  return context;
}
