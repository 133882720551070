import { drop } from "ramda";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export function useLanguageChange(rerender: any) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function onSelectionChanged(lang: string) {
    navigate(`/${lang}/${drop(7, location.pathname)}`);
  }

  useEffect(() => {
    const newSelector = document.getElementById("language-selection");
    if (newSelector) {
      newSelector.onchange = function (e: any) {
        onSelectionChanged(e.target.value);
        document.documentElement.lang = e.target.value;
        document.documentElement.setAttribute(
          "region",
          e.target.value.split("-")[1],
        );
      };
    }
  }, [i18n.language, rerender, location]);
}
