import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { equals, includes, map, pathOr } from "ramda";
import {
  ChangeEvent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { TextArea } from "../../../../../components/TeaxtArea";
import { DialogContext } from "../../../../../context/useDialog";
import { mediaQuery } from "../../../../../theme";
import { RequestChoiceGroup } from "../components/RequestChoiceGroup";
import { RequestDropdown } from "../components/RequestDropdown";
import { RequestTextField } from "../components/RequestTextField";
import { VALIDATION, isMandatoryField } from "../requestUtils";
import {
  CHECKOUT_MODAL_CONFIGURATION,
  UseCheckoutModalState,
  ValidationState,
} from "../useRequest";

type ValidationWrapperProps = {
  className: string;
  children: ReactNode;
  validation: boolean;
  validationMessage: string;
};

function ValidationWrapper({
  className,
  children,
  validation,
  validationMessage,
}: Readonly<ValidationWrapperProps>) {
  return (
    <div
      className={classNames([
        className,
        "validation-wrapper",
        { "is-invalid": !validation },
      ])}
    >
      {children}
      {!validation && (
        <div className="validation-warning">{validationMessage}</div>
      )}
    </div>
  );
}

interface InputRowProps {
  className?: string;
  label: string;
  type: string;
  value: any;
  placeholder?: string;
  disabled?: boolean;
  showLabel?: boolean;

  onChange(e: ChangeEvent<HTMLElement> | string): any;
}

function inputStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      flex: "1 0 33.333%",
    },
    inputWrapper: {
      display: "flex",
      flexFlow: "column",
      padding: "0.3rem 0",
      "#country": {
        padding: "-0.3em",
      },
      selectors: {
        ".is-full": {
          flex: "100%",
        },
        "&.is-invalid input": {
          border: `1px solid ${theme.palette.red}`,
          borderRadius: "6px",
        },
        ".validation-warning": {
          fontSize: "12px",
          color: theme.palette.themePrimary,
        },
      },
    },
    input: {
      zIndex: 1,
      paddingBottom: "calc(0.5em - 1px)",
      paddingTop: "calc(0.5em - 1px)",
      width: "100%",
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: "4px",
      boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
    },
    select: {
      padding: "11px 7px",
      width: "100%",
      height: "44px",
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: "4px",
      boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
      backgroundColor: theme.palette.white,
    },
    labelWrapper: {
      fontSize: "0.8em",
      fontWeight: "500",
      "&.is-invalid": {
        color: theme.palette.themePrimary,
      },
    },
    textArea: {
      width: "100%",
      ".textarea": {
        resize: "none",
        height: "200px",
        border: `1px solid ${theme.palette.neutralQuaternary}`,
        borderRadius: "4px",
        boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
      },
    },
  });
}

function personalInformationStyle() {
  return mergeStyleSets({
    personalInformation_wrapper: {
      display: "flex",
    },
    personalInformationRow: {
      display: "inline-flex",
      flexDirection: "initial",
      gap: "1rem",
      [mediaQuery.sm]: {
        flexFlow: "column",
        gap: "0",
      },
    },
    personal: {
      display: "flex",
      flexFlow: "row",
    },
    phoneRow: {
      gap: 0,
      position: "relative",
      [mediaQuery.sm]: {
        flexFlow: "row",
      },
    },
    telephoneCode: {
      position: "absolute",
      width: "110px",
    },
    telephone: {
      flexGrow: 1,
      label: {
        paddingLeft: "118px",
      },
      input: {
        paddingLeft: "118px",
      },
    },
  });
}

function InputRow({
  className,
  onChange,
  label,
  type,
  value = "",
  placeholder = "",
  disabled = false,
  showLabel = true,
}: Readonly<InputRowProps>) {
  const theme = useTheme();
  const inputStyled = useMemo(() => inputStyle(theme), [theme]);
  const { root, inputWrapper, labelWrapper, textArea } = inputStyled;
  const [valid, setValid] = useState<ValidationState>({
    validation: true,
    validationMessage: null,
  });
  const { validation, validationMessage } = valid;
  const { t, i18n } = useTranslation();
  const { PERSONAL_DROPDOWN_LIST, getPersonalDialogData } =
    useContext(DialogContext);

  useEffect(() => {
    getPersonalDialogData();
  }, [i18n.language]);

  return (
    <div className={classNames([className, root])}>
      <div>
        <div className="control">
          <ValidationWrapper
            className={inputWrapper}
            validation={validation}
            validationMessage={t(`t:common.${validationMessage}`)}
          >
            {showLabel && (
              <label
                className={classNames([
                  labelWrapper,
                  { "is-invalid": !validation },
                ])}
              >
                {t("t:common.input." + label)} {isMandatoryField(label) && "*"}
              </label>
            )}
            {equals(type, "input") && (
              <RequestTextField
                onChange={(event, value) => setValid(onChange(value ?? ""))}
                value={value}
              />
            )}
            {equals(type, "textarea") && (
              <TextArea
                maxLength={1024}
                className={textArea}
                placeholderLabel={placeholder}
                value={value}
                onChange={(e) => setValid(onChange(e))}
                disabled={disabled}
              />
            )}
            {equals(type, "dropdown") && (
              <RequestDropdown
                placeholder={placeholder}
                onChange={(value) => setValid(onChange(value))}
                value={value}
                options={map(
                  (option) => ({
                    value: option,
                    label: t(
                      `t:glossary.checkout.checkoutModal.dropDowns.${option}`,
                    ),
                  }),
                  pathOr<string[]>([], [label], PERSONAL_DROPDOWN_LIST),
                )}
              />
            )}
            {equals(type, "dropdownWithoutTranslation") && (
              <RequestDropdown
                placeholder={placeholder}
                onChange={(value) => setValid(onChange(value))}
                options={map(
                  (option) => ({ value: option, label: option }),
                  pathOr<string[]>([], [label], PERSONAL_DROPDOWN_LIST),
                )}
              />
            )}
            {equals(type, "radio") && (
              <RequestChoiceGroup
                options={map(
                  (option) => ({
                    ...option,
                    text: t(
                      `t:glossary.checkout.checkoutModal.radio.${option.text}`,
                    ),
                  }),
                  pathOr<{ key: string; text: string }[]>(
                    [],
                    ["RADIO_TRANSLATION_LIST", label],
                    CHECKOUT_MODAL_CONFIGURATION,
                  ),
                )}
              />
            )}
          </ValidationWrapper>
        </div>
      </div>
    </div>
  );
}

export function PersonalInformation({
  checkoutState,
  setCheckoutValue,
  disabledFields,
}: Readonly<UseCheckoutModalState>) {
  const personalInformationStyled = useMemo(
    () => personalInformationStyle(),
    [],
  );
  const { t } = useTranslation();
  const {
    personalInformation_wrapper,
    personalInformationRow,
    phoneRow,
    telephone,
    telephoneCode,
  } = personalInformationStyled;

  return (
    <Stack className={personalInformation_wrapper}>
      <InputRow
        label="salutation"
        showLabel={false}
        type="radio"
        value={checkoutState.salutation}
        onChange={setCheckoutValue("salutation")}
        disabled={includes("salutation", disabledFields!)}
      />
      <Stack className={personalInformationRow}>
        <InputRow
          label={"surname"}
          type="input"
          value={checkoutState.surname}
          onChange={setCheckoutValue("surname", [
            VALIDATION.MAX_LENGTH_INPUT,
            VALIDATION.VALIDATION_FIELDS,
          ])}
          disabled={includes("surname", disabledFields!)}
        />
        <InputRow
          label={"lastname"}
          type="input"
          value={checkoutState.lastname}
          onChange={setCheckoutValue("lastname", [
            VALIDATION.MAX_LENGTH_INPUT,
            VALIDATION.VALIDATION_FIELDS,
          ])}
          disabled={includes("lastname", disabledFields!)}
        />
      </Stack>
      <Stack className={personalInformationRow}>
        <Stack.Item>
          <InputRow
            label={"customerNumber"}
            type="input"
            value={checkoutState.customerNumber}
            onChange={setCheckoutValue("customerNumber", [
              VALIDATION.MAX_LENGTH_INPUT,
            ])}
            disabled={includes("customerNumber", disabledFields!)}
          />
        </Stack.Item>
        <Stack.Item grow={3}>
          <InputRow
            label={"emailAddress"}
            type="input"
            value={checkoutState.emailAddress}
            onChange={setCheckoutValue("emailAddress", [
              VALIDATION.MAX_LENGTH_INPUT,
              VALIDATION.VALIDATION_FIELDS,
            ])}
            disabled={includes("emailAddress", disabledFields!)}
          />
        </Stack.Item>
      </Stack>
      <Stack className={personalInformationRow}>
        <InputRow
          label={"department"}
          type="input"
          value={checkoutState.department}
          onChange={setCheckoutValue("department")}
          disabled={includes("department", disabledFields!)}
        />
        <InputRow
          label={"role"}
          type="input"
          value={checkoutState.role}
          onChange={setCheckoutValue("role", [VALIDATION.MAX_LENGTH_INPUT])}
          disabled={includes("role", disabledFields!)}
        />
      </Stack>
      <Stack className={personalInformationRow}>
        <Stack.Item>
          <InputRow
            label={"location"}
            type="input"
            value={checkoutState.location}
            onChange={setCheckoutValue("location", [
              VALIDATION.MAX_LENGTH_INPUT,
            ])}
            disabled={includes("location", disabledFields!)}
          />
        </Stack.Item>
        <Stack.Item grow={3}>
          <InputRow
            label={"street"}
            type="input"
            value={checkoutState.street}
            onChange={setCheckoutValue("street", [VALIDATION.MAX_LENGTH_INPUT])}
            disabled={includes("street", disabledFields!)}
          />
        </Stack.Item>
      </Stack>
      <Stack className={personalInformationRow}>
        <InputRow
          label={"country"}
          type="dropdown"
          value={{
            value: `country.${checkoutState.countryCode}`,
            label: t(
              `t:glossary.checkout.checkoutModal.dropDowns.${checkoutState.countryCode}`,
            ),
          }}
          onChange={setCheckoutValue("countryCode")}
          disabled={includes("countryCode", disabledFields!)}
          placeholder={t(`t:common.dropDown.pleaseSelect`)}
        />
        <InputRow
          label={"company"}
          type="input"
          value={checkoutState.company}
          onChange={setCheckoutValue("company", [VALIDATION.MAX_LENGTH_INPUT])}
          disabled={includes("company", disabledFields!)}
        />
      </Stack>
      <Stack className={classNames(personalInformationRow, phoneRow)}>
        <Stack.Item className={telephone}>
          <InputRow
            label={"telephone"}
            type="input"
            value={checkoutState.telephone}
            onChange={setCheckoutValue("telephone", [
              VALIDATION.MAX_LENGTH_INPUT,
              VALIDATION.VALIDATION_FIELDS,
            ])}
            disabled={includes("telephone", disabledFields!)}
          />
        </Stack.Item>
        <Stack.Item className={telephoneCode}>
          <InputRow
            label={"phone"}
            type="dropdownWithoutTranslation"
            value={checkoutState.phone}
            onChange={setCheckoutValue("phone")}
            disabled={includes("phone", disabledFields!)}
            placeholder={t(`t:common.dropDown.phonePleaseSelect`)}
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <InputRow
          label={"comment"}
          className="is-full"
          type="textarea"
          value={checkoutState.comment}
          onChange={setCheckoutValue("comment", [
            VALIDATION.MAX_LENGTH_COMMENT,
          ])}
          disabled={includes("comment", disabledFields!)}
        />
      </Stack>
    </Stack>
  );
}
