import i18n from "i18next";
import { pathOr, slice } from "ramda";
import { initReactI18next } from "react-i18next";
import { SettingsProps } from "setting";

import { ComponentName } from "@encoway/cui-configurator-components";

import { EncowayEnv } from "./context/useSettings";

export function createSettings(envs?: EncowayEnv): SettingsProps {
  return {
    name: "Lapp",
    imagePath: "api/catalog/media?id=",
    language: {
      tag: "de-DE",
      displayName: "Deutsch",
    },
    showroom: {
      url: pathOr("/lapp-cable-server", ["baseUrl"], envs),
      auth: {
        password: "WTvg=C3^dcx=xy3H",
        username: "services",
      },
    },
    oAuth: {
      openIdIssuerUrl: pathOr("NOT_SET", ["openIdIssuerUrl"], envs),
      clientId: pathOr("NOT_SET", ["clientId"], envs),
      ssoActive:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        pathOr("NOT_SET", ["ssoActive"], envs) === "true",
    },
    server: {
      embedded: {
        baseUrl: "https://configurator.lapp.com/lapp-cable-client",
      },
      reactAppEnvironment: pathOr("NOT_SET", ["reactAppEnvironment"], envs),
    },
    studio: {
      boms: {
        view: "encoway.calculationview",
        visible: {
          price: "visibility-price",
          amount: "visibility-amount",
          request: "visibility-request",
          eplan: "visibility-eplan",
          cart: "visibility-cart",
          commentary: "visibility-commentary",
          share: "visibility-share",
          copy: "visibility-copy",
          reset: "visibility-reset",
        },
        configuration: {
          undoable: "parameter-undoable",
          questionMark: "invisibility-questionmark",
        },
        cartEndpoint: "cart-endpoint",
        shopEndpoint: "shop-endpoint",
      },
      application: "application_texts",
      configurator: {
        fiber: "Fiber_Optic_Configurator",
        servo: "Servo_Assembly_Finder",
        trolley: "Cable_Trolley_System",
        connector: "Test_Stecker",
        spiral: "Spiral_Configurator",
        ic: "IC_Selector",
      },
      visualization: {
        cableLabelingContainerName: "Kabel",
        cableLabelingParameterName: "Kabelbeschriftung",
        visualizationContainerName: "Visualization",
        dimensionsContainerName: "Dimension",
        cableLengthParameterName: "Nutzlaenge",
        isBreakoutParameterName: "isSeperatedDimensions",
        amountOfFibersParameterName: "Faserzahl",
      },
      viewport: {
        parameter: {
          infoIcon: [ComponentName.Dropdown, ComponentName.RadioButton],
        },
      },
    },
    analytics: {
      siteid: "configurator.lapp.com",
      pageId: "HomeV1",
      content: {
        configure: "configure",
        openCheckout: "inquiry",
        checkout: "inquirySubmitted",
      },
    },
    tracking: {
      sessionTrackingInterval: parseFloat(
        pathOr("NOT_SET", ["sessionTrackingInterval"], envs),
      ),
      validSessionLimit: parseFloat(
        pathOr("NOT_SET", ["validSessionLimit"], envs),
      ),
    },
  };
}

i18n
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: slice(2, 7, document.location.hash) || "de-DE",
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export const VisualizationSettings = {
  visualization: {
    baseUrl: "https://visualization.encoway.cloud/vcs",
    version: "latest",
    token:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXN1YWxpemF0aW9uIiwiYXVkIjoid3d3LmVuY293YXkuZGUiLCJpc3MiOiJWZXJ0cmllYiIsImlhdCI6MTYyMzg1Mjg0NCwiZXhwIjoxOTI0OTA1NjAwLCJjYWQiOlsiZHhmIiwiZHdnIl0sInRvb2xzIjpbImFuY2hvci1lZGl0b3IiLCJtYXRlcmlhbC1lZGl0b3IiLCJtYXRlcmlhbC1hc3NpZ25tZW50LWVkaXRvciIsInJ1bGUtZWRpdG9yIl0sInJlbmRlcmluZyI6ImhkIn0.ecR0kVYLzi0-BPXsJ_slZtJjATB3SLUuDo_r5CtY4s8",
    settings: {
      features: {
        Gizmo: "off",
        OrientationIndicator: "off",
        Selection: "off",
        HighlightHoverItem: "off",
        ContextMenu: "off",
      },
      ui: {
        widgets: {
          progressSpinner: "southEast",
        },
        controls: {
          rotation: true,
          autozoom: "isometric",
          minDistance: 60,
          maxDistance: 300,
          minAzimuthAngle: -Infinity,

          maxAzimuthAngle: Infinity,
          minPolarAngle: 0,
          maxPolarAngle: Math.PI,
        },
        sidebar: {
          type: "none",
        },
        camera: {
          near: 10,
          far: 100000.0,
          fov: 60.0,
          position: {
            x: 50,
            y: 50,
            z: 50,
          },
        },
        renderer: {
          shadowMap: {
            enabled: true, // If true, use shadow maps in the scene. Default is false
            type: 2, // Defines shadow map type. 0: THREE.BasicShadowMap, 1: THREE.PCFShadowMap, 2: THREE.PCFSoftShadowMap, 3: THREE.VSMShadowMap
          },
        },

        contextActions: false,
        globalDrop: false,
        background: "rgb(245, 245, 245)",
        lights: [
          {
            type: "ambient",
            name: "#AmbientLight",
            color: "#404040",
            intensity: 1,
            castShadow: true,
          },
          {
            type: "directional",
            position: {
              x: 2.6,
              y: 100,
              z: -3,
            },
            name: "#BackLight",
            intensity: 0.75,
          },
          {
            type: "directional",
            position: {
              x: -2,
              y: 120,
              z: 1,
            },
            name: "#FrontLight",
            intensity: 1,
          },
          {
            type: "directional",
            position: {
              x: 19,
              y: 140,
              z: 40,
            },
            name: "#TopFrontLight",
            intensity: 0.5,
          },
          {
            type: "directional",
            position: {
              x: 0,
              y: 155,
              z: 0,
            },
            name: "#TopLight",
            intensity: 0.5,

            castShadow: true,
            shadow: {
              camera: {
                near: 0.1,
                far: 1000,
                left: -500,
                bottom: -500,
                right: 500,
                top: 500,
              },
              bias: 0.0001,
              blurSamples: 1,
              mapSize: {
                width: 512,
                height: 512,
              },
              normalBias: 0,
              radius: 1,
            },
          },
        ],
        components: {
          catalog: false,
          header: false,
          toolbar: false,
        },
        toolbar: {
          ViewIsometric: true,
          ViewTop: true,
          ViewFront: true,
          select: true,
          measure_distance: false,
          dimensioning: {
            singleDimensioning: false,
            datumDimensioning: false,
            customDimensioning: true,
          },
          grid: false,
          snap_objects: false,
          help: false,
          configuration: true,
          autozoom: false,
        },
        dimensioning: {
          autoEnabled: false,
          lineColor: "#ef7b22",
          textColor: "#000000",
        },
      },
    },
  },
};
