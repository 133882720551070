import i18n from "i18next";
import { ExtendedProduct } from "product";
import { find, reduce } from "ramda";
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import { CatalogService } from "@encoway/c-services-js-client";

import { toExtendedCharacteristic } from "./productUtils";
import { useSettings } from "./useSettings";

export type ExtendedProductCache = ExtendedProduct & { lang: string };

export interface ProductContextType {
  getProduct: (id: string, lang?: string) => Promise<ExtendedProductCache>;
  cachedProducts: ExtendedProductCache[];
  clearCache: () => void;
}

export const ProductContextProvider = createContext<
  ProductContextType | undefined
>(undefined);

export function ProductProvider({ children }: { children: ReactNode }) {
  const { settings, http } = useSettings();
  const [cachedProducts, setCachedProducts] = useState<ExtendedProductCache[]>(
    [],
  );
  const catalogService = useMemo(
    () => new CatalogService(http, settings.showroom.url, i18n.language),
    [settings, http, i18n.language],
  );
  const getProduct = useCallback(
    async (id: string, lang?: string): Promise<ExtendedProductCache> => {
      const currentLang = lang || settings.language.tag;

      const cachedProduct = find(
        (p) => p.id === id && p.lang === currentLang,
        cachedProducts,
      );
      if (cachedProduct) {
        return cachedProduct;
      }

      try {
        const product = await catalogService.product(id, currentLang);
        const characteristicValues = reduce(
          toExtendedCharacteristic(product.product.characteristicValues),
          {},
          product.characteristics,
        );
        const extendedProduct: ExtendedProductCache = {
          ...product.product,
          characteristicValues,
          lang: currentLang,
        };
        setCachedProducts((prevCache) => [...prevCache, extendedProduct]);
        return extendedProduct;
      } catch (error) {
        console.error("Error fetching product:", error);
        throw error;
      }
    },
    [cachedProducts, catalogService, settings.language.tag],
  );

  const clearCache = useCallback(() => {
    setCachedProducts([]);
  }, []);

  const value = {
    getProduct,
    cachedProducts,
    clearCache,
  };

  return (
    <ProductContextProvider.Provider value={value}>
      {children}
    </ProductContextProvider.Provider>
  );
}
