import { Products } from "product";
import {
  filter,
  flatten,
  includes,
  isNil,
  map,
  pathOr,
  pick,
  split,
} from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";

const INDIVIDUAL_CHARACTERISTIC_PATH = (article: string) => [
  article,
  "characteristicValues",
  "bom-individual-characteristic",
  "values",
  0,
];

export const retrieveBomIndividualCharacteristic = (
  article: string,
  data: Products,
) =>
  split(",", pathOr<string>("", INDIVIDUAL_CHARACTERISTIC_PATH(article), data));

export function findSections(
  container: ContainerTO,
  sectionNames: string[],
  result: any[],
): ContainerTO[] {
  const found = filter(
    ({ name }) => includes(name, sectionNames),
    container.children,
  );

  const results = map(
    (child) => findSections(child, sectionNames, result),
    container.children,
  );

  if (isNil(found)) {
    return result;
  }

  return flatten([...result, ...results, ...found]);
}

export function extractParameters(sections: ContainerTO[]) {
  return pathOr(
    [],
    [0, "parameters"],
    flatten(map(pick(["parameters"]), sections)),
  );
}
