import { DetermineRoute } from "../DetermineRoute";
import { OldPathRedirect } from "../OldPathRedirect";
import { LoadConfiguration } from "../pages/configurator/LoadConfiguration";
import { Layout } from "../pages/layout/Layout";

export const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <DetermineRoute />,
        index: true,
      },
      {
        path: ":locale",
        children: [
          {
            index: true,
            element: <DetermineRoute />,
          },
          {
            path: "configuration",
            children: [
              {
                index: true,
                element: <LoadConfiguration />,
              },
              {
                path: "load/:article/:load",
                element: <LoadConfiguration />,
              },
              {
                path: ":article/:session/mail/:orderType",
                element: <LoadConfiguration />,
              },
              {
                path: ":article/:session",
                element: <LoadConfiguration />,
              },
              {
                path: ":article",
                element: <LoadConfiguration />,
              },
            ],
          },
          {
            path: "configure",
            children: [
              {
                path: "productId/:article/:session",
                element: <OldPathRedirect />,
              },
              {
                path: "productId/:article",
                element: <OldPathRedirect />,
              },
            ],
          },
        ],
      },
    ],
  },
];
