import { BomProduct } from "bom";
import { equals, isEmpty, keys, reduce } from "ramda";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { useAnalyticsContext } from "../../../context/useAnalytics";
import { AppContext } from "../../../context/useApp";
import { BomContext } from "../../../context/useBom";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { useSettings } from "../../../context/useSettings";
import {
  CheckoutDTO,
  sendConfirmationMail,
  sendConfirmationMailPublic,
} from "../../../service/mailService";
import { InvalidProduct, toInvalidProducts } from "../../../utils/moqUtils";
import { ORDER_TYPE } from "./request/useRequest";

const initialDialogState: DialogState = {
  loading: {
    cart: false,
  },
  open: {
    login: false,
    reset: false,
    mail: false,
    cart: false,
    cartFail: false,
    thanksDialog: false,
    failedDialog: false,
    share: false,
    copy: false,
    moq: false,
  },
  type: ORDER_TYPE.STANDARD_ORDER,
};

export type DialogOpen = {
  login: boolean;
  reset: boolean;
  mail: boolean;
  cart: boolean;
  cartFail: boolean;
  thanksDialog: boolean;
  failedDialog: boolean;
  share: boolean;
  copy: boolean;
  moq: boolean;
};

export type DialogState = {
  open: DialogOpen;
  type: ORDER_TYPE;
  loading: {
    cart: boolean;
  };
};

export type BomDialogStore = {
  sendToCart(): void;
  toggle(prop: keyof DialogOpen, type?: ORDER_TYPE): () => void;
  sendMail(checkoutDTO: CheckoutDTO): Promise<void>;
  openMail(orderType: ORDER_TYPE): void;
  invalidProducts: InvalidProduct[];
} & DialogState;

export function useBomDialog(ignoreAuthRedirect: boolean): BomDialogStore {
  const { settings, axios, axiosSimple } = useSettings();
  const { auth } = useContext(AppContext);
  const { article, orderType, locale, session } =
    useParams<ConfigurationRoutes>();
  const {
    bom,
    total: { amount },
    sendCart,
  } = useContext(BomContext);
  const { send } = useAnalyticsContext();
  const { i18n } = useTranslation();
  const { cfg } = useConfigurationContext();

  const [{ open, type, loading }, setDialogState] = useState<DialogState>({
    ...initialDialogState,
    type: orderType || initialDialogState.type,
    open: {
      ...initialDialogState.open,
      mail: ignoreAuthRedirect ? false : !!orderType,
    },
  });
  const [invalidProducts, setInvalidProducts] = useState<InvalidProduct[]>([]);
  const navigate = useNavigate();

  function toggle(prop: keyof DialogOpen, newType?: ORDER_TYPE) {
    const state = open[prop];
    if (state === undefined) {
      throw new Error(`Prop ${prop} not key of DialogState`);
    }
    return function (): void {
      const allClosed = reduce(
        (acc: DialogOpen, key: keyof DialogOpen) => ({ ...acc, [key]: false }),
        open,
        keys(open),
      );
      if (open[prop]) {
        navigate(`/${locale}/configuration/${article}/${session}`, {
          replace: true,
        });
      }
      if (newType) {
        setDialogState((prev) => ({
          ...prev,
          open: { ...allClosed, [prop]: !prev.open[prop] },
          type: newType,
        }));
      } else {
        setDialogState((prev) => ({
          ...prev,
          open: { ...allClosed, [prop]: !prev.open[prop] },
        }));
      }
    };
  }

  function sendToCart() {
    setDialogState((prev) => ({
      ...prev,
      loading: { ...prev.loading, cart: true },
    }));
    sendCart()
      .then(() => {
        setDialogState((prev) => ({
          ...prev,
          loading: { ...prev.loading, cart: false },
        }));
        toggle("cart")();
      })
      .catch((e) => {
        console.warn(e);
        setDialogState((prev) => ({
          ...prev,
          loading: { ...prev.loading, cart: false },
        }));
        toggle("cartFail")();
      });
  }

  async function sendMail(checkoutDTO: CheckoutDTO): Promise<void> {
    if (!cfg) {
      throw new Error("no configuration available");
    }
    try {
      if (auth.user && auth.token) {
        await sendConfirmationMail(
          axiosSimple,
          cfg.id(),
          checkoutDTO,
          i18n.language,
          auth.token,
        );
      } else {
        await sendConfirmationMailPublic(
          axios,
          cfg.id(),
          checkoutDTO,
          i18n.language,
        );
      }
      toggle("thanksDialog")();
    } catch {
      toggle("failedDialog")();
    }
  }

  async function openMail(newType: ORDER_TYPE) {
    const invalidProducts = reduce<BomProduct, InvalidProduct[]>(
      toInvalidProducts(amount),
      [],
      bom,
    );
    setInvalidProducts(invalidProducts);
    if (!isEmpty(invalidProducts)) {
      toggle("moq")();

      return;
    }

    if (equals(newType, "STANDARD_ORDER")) {
      send(settings.analytics.content.openCheckout, i18n.language, cfg);
    }
    if (settings.oAuth.ssoActive) {
      if (auth.user) {
        toggle("mail", newType)();
      } else {
        setDialogState((prev) => ({
          ...prev,
          open: { ...prev.open, login: true },
          type: newType,
        }));
      }
    } else {
      toggle("mail", newType)();
    }
  }

  return {
    sendMail,
    sendToCart,
    toggle,
    openMail,
    open,
    type,
    loading,
    invalidProducts,
  };
}
