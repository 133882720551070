import i18next from "i18next";
import { match, pathOr, slice, toLower } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import {
  ENCOWAY_ARTICLE_KEY,
  ENCOWAY_SESSION_KEY,
} from "../../../DetermineRoute";
import { ConfirmationDialog } from "../../../components/Dialog/ConfirmationDialog";
import { ShareDialog } from "../../../components/Dialog/ShareDialog";
import { SimpleDialog } from "../../../components/Dialog/SimpleDialog";
import { AppContext } from "../../../context/useApp";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { useSettings } from "../../../context/useSettings";
import { forceInvalidProducts } from "../../../service/bomService";
import { MoqDialog } from "../connector/components/MoqDialog";
import { BomCopyDialog } from "./BomCopyDialog";
import { BomLoginReset } from "./BomLoginReset";
import { Request } from "./request/Request";
import { BomDialogStore } from "./useBomDialog";

type BomDialogsProps = {
  store: BomDialogStore;
};

export function BomDialogs({
  store: { invalidProducts, open, type, toggle, sendMail },
}: Readonly<BomDialogsProps>) {
  const { login } = useContext(AppContext);
  const { eventBus, cfg } = useConfigurationContext();
  const navigate = useNavigate();
  const { article, session, locale } = useParams<ConfigurationRoutes>();
  const { t, i18n } = useTranslation();
  const { axios } = useSettings();

  function handleClickOpenCart() {
    const language: Locale = {
      lang1: pathOr(
        "",
        [0],
        match(/^[a-zA-Z_]+[^-]/, locale ?? i18next.language),
      ),
      lang2: toLower(slice(3, 5, locale ?? i18n.language)),
    };
    window.location.href = `${window.location.origin}/${language.lang1}/${language.lang2}/cart`;
  }

  function handleConfigurationReset() {
    localStorage.removeItem(ENCOWAY_ARTICLE_KEY);
    localStorage.removeItem(ENCOWAY_SESSION_KEY);
    navigate(`/${i18n.language}/configuration/${article}`);
  }

  function onMoqConfirm() {
    forceInvalidProducts(axios, cfg!.id(), invalidProducts, eventBus!);

    toggle("moq")();
  }

  return (
    <>
      <SimpleDialog
        ok={handleConfigurationReset}
        dismiss={toggle("reset")}
        okText={t("t:common.button.resetConfiguration")}
        cancelText={t("t:common.button.cancel")}
        label={t(
          "t:glossary.checkout.resetConfiguration.resetYourConfiguration",
        )}
        text={t("t:glossary.checkout.resetConfiguration.resetYesNo")}
        isOpen={open.reset}
      />
      <SimpleDialog
        ok={handleClickOpenCart}
        dismiss={toggle("cart")}
        okText={t("t:common.button.showCart")}
        cancelText={t("t:common.button.cancel")}
        label={t("t:glossary.checkout.cart.showCart")}
        text={t("t:glossary.checkout.cart.cartYesNo")}
        isOpen={open.cart}
      />
      <SimpleDialog
        ok={toggle("cartFail")}
        dismiss={toggle("cartFail")}
        okText={t("t:common.button.ok")}
        label={t("t:glossary.checkout.cart.errorHeader")}
        text={t("t:glossary.checkout.cart.errorMessage")}
        isOpen={open.cartFail}
      />
      <ConfirmationDialog
        isOpen={open.thanksDialog}
        dismiss={toggle("reset")}
        type={type}
      />
      <ConfirmationDialog
        isOpen={open.failedDialog}
        label={t("t:glossary.checkout.confirmationModal.failed")}
        dismiss={toggle("reset")}
      />
      <ShareDialog
        ok={toggle("share")}
        dismiss={toggle("share")}
        okText={t("t:common.button.copyLink")}
        cancelText={t("t:common.button.cancel")}
        label={t("t:glossary.checkout.shareModal.share")}
        isOpen={open.share}
      />
      <BomLoginReset
        request={toggle("mail")}
        login={() =>
          login(`${locale}/configuration/${article}/${session}/mail/${type}`)
        }
        dismiss={toggle("login")}
        isOpen={open.login}
      />
      <Request
        isOpen={open.mail}
        orderType={type}
        onModalCancelClick={toggle("mail")}
        onModalSubmitClick={sendMail}
      />
      <ShareDialog
        ok={toggle("share")}
        dismiss={toggle("share")}
        okText={t("t:common.button.copyLink")}
        cancelText={t("t:common.button.cancel")}
        label={t("t:glossary.checkout.shareModal.share")}
        isOpen={open.share}
      />
      <BomCopyDialog isOpen={open.copy} dismiss={toggle("copy")} />
      <MoqDialog
        ok={onMoqConfirm}
        dismiss={toggle("moq")}
        isOpen={open.moq}
        invalidProducts={invalidProducts}
      />
    </>
  );
}
