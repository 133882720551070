import { equals, find, keys, path, pathOr, propEq, reduce } from "ramda";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { GuiTO, ParameterTO, Product } from "@encoway/c-services-js-client";

import { useProduct } from "../hooks/useProduct";
import { useConfigurationContext } from "./useConfiguration";
import { ProductContext } from "./useProducts";
import { useSettings } from "./useSettings";
import { escapeProperties, getReference } from "./visualizationUtils";

export type ViewPortProperties = {
  vis_ref: string[];
  vis_default_value: string | undefined;
  vis_min_width: string;
  vis_order: number;
  vis_centered: boolean;
  vis_mirror: boolean;
  vis_stretched: boolean;
  vis_background_image: boolean;
  vis_type: string;
};

export type VisProperty = ViewPortProperties & {
  parameter: ParameterTO | undefined;
  vis_image: string | undefined;
};

export type VisSettings = {
  enabled: "top" | "table" | "disabled" | undefined;
  height: string;
  width: string;
  padding: string;
};

export type VisStore = {
  vis: VisProperty[];
  visSettings: VisSettings;
};

const initialVis: VisStore = {
  vis: [],
  visSettings: {
    enabled: undefined,
    height: "300px",
    width: "400px",
    padding: "2px",
  },
};

export const VisualizationContext = createContext<VisStore>(initialVis);

export function VisualizationProvider({
  children,
}: Readonly<{ children: ReactNode }>): React.ReactElement {
  const { guiTO } = useConfigurationContext();
  const { products } = useContext(ProductContext);
  const { article } = useParams<{ article: string }>();
  const settings = useSettings();
  const { getProduct } = useProduct();

  const product = useMemo(
    () => path<Product>([article!], products),
    [products, article],
  );
  const visSettings: VisSettings = useMemo(
    () => ({
      enabled: path(
        ["characteristicValues", "vis_enabled", "values", 0],
        product,
      ),
      height: pathOr(
        "inherit",
        ["characteristicValues", "vis_height", "values", 0],
        product,
      ),
      width: pathOr(
        "inherit",
        ["characteristicValues", "vis_width", "values", 0],
        product,
      ),
      padding: pathOr(
        "0",
        ["characteristicValues", "vis_padding", "values", 0],
        product,
      ),
    }),
    [product],
  );

  const determineVisImage = useCallback(
    async (
      props: ViewPortProperties,
      parameter: ParameterTO | undefined,
      currentProduct: Product | undefined,
    ): Promise<string | undefined> => {
      if (parameter && parameter.terminal) {
        if (equals(props.vis_type, "productgroup")) {
          const productGroupId = path<string>(
            ["selectedValues", 0, "value"],
            parameter,
          );
          if (productGroupId) {
            const newProduct = await getProduct(productGroupId);
            return path<string>(
              ["characteristicValues", "ref_primary_image", "values", 0],
              newProduct,
            );
          }
        }
        return `${settings.settings.showroom.url}/${
          settings.settings.imagePath
        }${path<string>(["selectedValues", 0, "imageUrl"], parameter)}`;
      }

      if (parameter && props.vis_default_value) {
        return pathOr(
          undefined,
          [
            "characteristicValues",
            props.vis_default_value!,
            "values",
            0,
            "uri",
          ],
          currentProduct,
        );
      }

      return undefined;
    },
    [settings.settings.showroom.url, settings.settings.imagePath, getProduct],
  );

  const determineVis = useCallback(
    async (
      currentGuiTO: GuiTO | undefined,
      currentProduct: Product | undefined,
    ): Promise<VisProperty[]> => {
      if (!currentProduct || !currentGuiTO) return [];

      const visContainer = find(
        propEq("name", "Visualization"),
        currentGuiTO.rootContainer.children,
      );
      if (!visContainer) return [];

      return await Promise.all(
        visContainer.parameters.map(async (param) => {
          const unparsedVPs = param.viewPortProperties as ViewPortProperties;
          const props = reduce(
            escapeProperties(unparsedVPs),
            unparsedVPs,
            keys(unparsedVPs),
          );
          const parameter = getReference(
            currentGuiTO.rootContainer.children,
            props.vis_ref,
          );

          const vis_image = await determineVisImage(
            props,
            parameter,
            currentProduct,
          );
          return {
            ...props,
            parameter,
            vis_image,
          };
        }),
      );
    },
    [determineVisImage],
  );

  const [vis, setVis] = useState<VisProperty[]>([]);

  useEffect(() => {
    const updateVis = async () => {
      const newVis = await determineVis(guiTO, product);
      setVis(newVis);
    };
    updateVis().then();
  }, [determineVis, guiTO, product]);

  const value = useMemo<VisStore>(
    () => ({
      vis,
      visSettings,
    }),
    [vis, visSettings],
  );

  return (
    <VisualizationContext.Provider value={value}>
      {children}
    </VisualizationContext.Provider>
  );
}
