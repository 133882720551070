import React, { useMemo } from "react";

import { VisProperty } from "../../context/VisualizationProvider";
import { VisImage } from "./VisImage";

export function VisEntry({
  visProperty,
}: Readonly<{ visProperty: VisProperty }>) {
  const imageAlt = useMemo(() => {
    if (visProperty.parameter && visProperty.parameter.terminal) {
      return visProperty.parameter.translatedName ?? visProperty.parameter.name;
    }
    return "Default Image";
  }, [visProperty.parameter]);

  if (!visProperty.vis_image) {
    return null;
  }

  return (
    <VisImage
      uri={visProperty.vis_image}
      alt={imageAlt}
      visProperty={visProperty}
    />
  );
}
