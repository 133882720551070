import {
  IFontStyles,
  ITheme,
  mergeStyleSets,
  Text,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { ReactNode, useMemo } from "react";

type SectionProps = {
  className?: string;
  label: string;
  variant?: keyof IFontStyles;
  borderColor?: string;
  children?: ReactNode;
};

function sectionStyle(theme: ITheme) {
  return mergeStyleSets({
    text: {
      color: theme.palette.black,
    },
  });
}

export function Section({
  className,
  label,
  variant,
  children,
}: Readonly<SectionProps>) {
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);

  return (
    <div className={classNames(sectionStyled.text, className)}>
      {children}
      <Text variant={variant || "large"}>{label}</Text>
    </div>
  );
}
