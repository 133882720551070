import { useAuth0 } from "@auth0/auth0-react";
import { mergeStyleSets, PrimaryButton, Stack } from "@fluentui/react";
import { isNil } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../context/useApp";
import { useExternalHTML } from "../../hooks/useExternalHTML";
import { Reset } from "./Reset";
import { useLanguageChange } from "./useLangaugeChange";

const styles = mergeStyleSets({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "12px 12px 0 0",
  },
  button: {
    borderRadius: "0.25em",
    width: "auto",
  },
});

export function Header() {
  const { data } = useExternalHTML("header");
  const { auth } = useContext(AppContext);
  const { logout } = useAuth0();
  const { t } = useTranslation();
  useLanguageChange(data);

  return data ? (
    <>
      {!isNil(auth?.user) && (
        <Stack className={styles.root}>
          <PrimaryButton
            className={styles.button}
            onClick={() => {
              logout({
                returnTo: `${window.location.origin}${window.location.pathname}`,
              });
              sessionStorage.removeItem("auth");
            }}
          >
            {t("t:common.button.logout")}
          </PrimaryButton>
        </Stack>
      )}
      <div dangerouslySetInnerHTML={{ __html: data }} />
      <Reset />
    </>
  ) : null;
}
