import classNames from "classnames";
import React, { ChangeEvent } from "react";

interface MaxLengthTextFieldProps {
  className?: string;
  placeholderLabel?: string;
  headlineLabel?: string;
  value: string;
  isLoading?: boolean;
  maxLength: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => any;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => any;
  disabled?: boolean;
}

export function TextArea(props: Readonly<MaxLengthTextFieldProps>) {
  const {
    className,
    maxLength,
    onChange,
    onBlur,
    placeholderLabel,
    headlineLabel,
    value,
    isLoading = false,
    disabled = false,
  } = props;

  return (
    <div className={classNames(["max-length-textfield", className])}>
      {headlineLabel && (
        <h6 className="checkbox-sub-title has-text-weight-bold title is-6">
          {headlineLabel}
        </h6>
      )}
      <div
        className={classNames("textfield-wrapper control", {
          "is-loading": isLoading,
        })}
      >
        <textarea
          maxLength={maxLength}
          style={{ width: "100%" }}
          className="textarea"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholderLabel || ""}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
