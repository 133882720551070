import { equals, isNil, path } from "ramda";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useConfigurationContext } from "./useConfiguration";
import { ProductContext } from "./useProducts";

export const VALUE_AMOUNT = 25;

type TabState = {
  openTab: OpenTab;
  setOpen: (containerName: string) => void;
  onMore: () => void;
};

interface OpenTab {
  active: string | undefined;
  last: string | undefined;
  numValues: number;
}

const INITIAL_STATE: TabState = {
  openTab: {
    active: undefined,
    last: undefined,
    numValues: VALUE_AMOUNT,
  },
  setOpen: () => new Error("setOpen is not implemented"),
  onMore: () => new Error("onMore is not implemented"),
};

export const TabContext = createContext<TabState>(INITIAL_STATE);

export function TabContextProvider({ children }: PropsWithChildren<unknown>) {
  const { getProducts } = useContext(ProductContext);
  const { article } = useConfigurationContext();
  const [openTab, setOpenTab] = useState<OpenTab>({
    active: undefined,
    last: undefined,
    numValues: VALUE_AMOUNT,
  });

  function onOpenTab(containerName: string): void {
    if (equals(containerName, openTab.active)) {
      setOpenTab((prev) => ({
        numValues: VALUE_AMOUNT,
        active: undefined,
        last: prev.active,
      }));
    } else {
      setOpenTab((prev) => ({
        numValues: VALUE_AMOUNT,
        active: containerName,
        last: prev.active,
      }));
    }
  }

  function onMore() {
    setOpenTab((prev) => ({
      ...prev,
      numValues: prev.numValues + VALUE_AMOUNT,
    }));
  }

  useEffect(() => {
    (async () => {
      const data = await getProducts(article!);
      const DEFAULT_SECTION_PATH = [
        article!,
        "characteristicValues",
        "default_open_section",
        "values",
        0,
      ];
      const defaultSection = path<string>(DEFAULT_SECTION_PATH, data);
      if (defaultSection) {
        onOpenTab(defaultSection);
      }
    })();
  }, []);

  const value = useMemo(
    () => ({
      openTab: openTab,
      setOpen: onOpenTab,
      onMore,
    }),
    [openTab],
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
}

export function useTab() {
  const context = useContext(TabContext);
  if (isNil(context)) {
    throw new Error("useTab must be used within TabContext");
  }
  return context;
}
