import axios from "axios";
import { path } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { isStandalone } from "../utils";
import { determineContentURI } from "./useExternalHTMLUtils";

export function useExternalHTML(name: string) {
  const { i18n } = useTranslation();
  const [externalHTML, setExternalHTML] = useState<{
    loading: boolean;
    data: string | undefined;
  }>({
    loading: true,
    data: undefined,
  });
  const params = useParams<ConfigurationRoutes>();

  useEffect(() => {
    if (isStandalone()) {
      (async () => {
        let _data;
        try {
          const { data } = await axios.get<string>(
            determineContentURI(name, i18n.language, path(["article"], params)),
          );
          _data = data;
        } catch {
          try {
            const { data } = await axios.get<string>(
              determineContentURI(name, i18n.language),
            );
            _data = data;
          } catch {
            const { data } = await axios.get<string>(determineContentURI(name));
            _data = data;
          }
        } finally {
          setExternalHTML({ loading: false, data: _data });
        }
      })();
    }
  }, [i18n.language, name, params]);

  return externalHTML;
}
