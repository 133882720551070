import { ReactNode } from "react";

import { AuthProvider } from "./AuthProvider";
import { ProductProvider } from "./context/ProductProvider";
import { VisualizationProvider } from "./context/VisualizationProvider";
import { AnalyticsContextProvider } from "./context/useAnalytics";
import { AppStore } from "./context/useApp";
import { BomStore } from "./context/useBom";
import { ConfigurationContextProvider } from "./context/useConfiguration";
import { DialogStore } from "./context/useDialog";
import { ProductStore } from "./context/useProducts";

export function Provider({ children }: Readonly<{ children: ReactNode }>) {
  return (
    <AuthProvider>
      <AnalyticsContextProvider>
        <ProductStore>
          <ProductProvider>
            <ConfigurationContextProvider>
              <AppStore>
                <VisualizationProvider>
                  <DialogStore>
                    <BomStore>{children}</BomStore>
                  </DialogStore>
                </VisualizationProvider>
              </AppStore>
            </ConfigurationContextProvider>
          </ProductProvider>
        </ProductStore>
      </AnalyticsContextProvider>
    </AuthProvider>
  );
}
