import { IProcessedStyleSet } from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import React from "react";

import { IEpriStyles } from "@encoway/cui-configurator-components";

export interface TooltipTextProps {
  classNames: IProcessedStyleSet<IEpriStyles>;
  text?: string;
}

export const TooltipText = (props: TooltipTextProps) => {
  const { classNames, text } = props;
  return (
    <TooltipHost
      content={text || ""}
      className={classNames.tooltip}
      styles={{
        root: { display: "inline-block", maxWidth: "100%" },
      }}
    >
      <Text block nowrap className={classNames.text}>
        {text}
      </Text>
    </TooltipHost>
  );
};
