import { isNil, replace, toUpper } from "ramda";

export function determineContentURI(
  name: string,
  fullLocale?: string,
  productId?: string,
) {
  if (isNil(fullLocale)) {
    return `/static/${name}EN.html`;
  }
  const currentLanguage = toUpper(replace(/-\w+$/, "", fullLocale));
  if (isNil(productId)) {
    return `/static/${name}${currentLanguage}.html`;
  }
  return `/static/${name}${currentLanguage}_${toUpper(productId)}.html`;
}
